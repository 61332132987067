import Navigation from "../Navigation/Navigation";
import "./Header.scss";

function Header() {
  return (
    <header>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div id="logo">
              <a href="index.html">
                <h2>
                  Julien<span>&amp;</span>Amélie
                </h2>
              </a>
            </div>
            <Navigation></Navigation>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
