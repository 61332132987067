import "./App.scss";

import Header from "./Components/Header/Header";
import Home from "./Pages/Home/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Portrait from "./Pages/Portrait/Portrait";
import Location from "./Pages/Location/Location";
import Theme from "./Pages/Theme/Theme";
import Honeymoon from "./Pages/Honeymoon/Honeymoon";
import Contact from "./Pages/Contact/Contact";
import Response from "./Pages/Response/Response";

function App() {
  return (
    <Router>
      <div className="App">
        <div id="wrapper">
          <Header></Header>

          <div id="content" className="no-bottom no-top">
            <Switch>
              <Route path="/contact">
                <Contact />
              </Route>
              <Route path="/cadeau-de-mariage">
                <Honeymoon />
              </Route>
              <Route path="/theme">
                <Theme />
              </Route>
              <Route path="/lieux">
                <Location />
              </Route>
              <Route path="/notre-portrait">
                <Portrait />
              </Route>
              <Route path="/rsvp">
                <Response />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
