import "./Theme.scss";

function Theme() {
  return (
    <>
      <section
        id="subheader"
        className="dark no-top no-bottom"
        style={{
          backgroundImage:
            "url(images/photos/headers/practical-information.jpg)",
          backgroundPosition: "center center",
          backgroundSize: "contain",
        }}
      >
        <div class="wrap">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 text-center fadeScroll relative"
                data-scroll-speed="2"
              >
                <h2 data-scroll-speed="8">Infos</h2>
                <div class="wm" data-scroll-speed="3">
                  Pratiques
                </div>
                <div class="spacer-double"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div id="content">
        <div class="container">
          <img
            src="images/photos/gifs/jump.gif"
            width="400"
            alt="Animation"
            className="float-end ms-4 mb-4 mt-5"
            style={{ borderRadius: "5px", transform: "rotate(5deg)" }}
          />
          <h3>Un Thème qui ressemble à nos tourtereaux : la Musique !</h3>

          <p>
            Mais pourquoi donc ont-ils choisi ça ?! Eh bien, évidemment parce
            qu’ils sont musiciens (amateurs hein, pas professionnels, même s’ils
            ont fait leur séance photo sur une des plus belles scènes de concert
            de Lyon : le Transbordeur !).
          </p>

          <p>
            Quand Amélie laisse doucement se balader ses mains sur les cordes de
            sa harpe pour jouer de douces mélodies, Julien branche son ampli et
            nous joue du métal ou chante (un peu faux) sur du rock à s’en
            décoiffer les cheveux ! Et quand elle a vraiment mal aux oreilles,
            Amélie part se réfugier dans une salle de danse où le Modern Jazz
            n’a plus aucun secret pour elle !
          </p>
          <p>
            Et puis, ils ont quand même rythmé leur vie à deux autour de
            nombreux concerts ! Bien qu’ils n’écoutent a priori pas du tout le
            même style musical, ils se retrouvent autour de Superbus, des
            Guns’N’Roses ou encore de Macklemore et des Red Hot Chili Peppers.
          </p>
          <p>
            <b>
              Evidemment, le code vestimentaire du mariage est inspiré du Thème
              de la Musique et se complète par quelques autres directives :
            </b>
          </p>
          <ul>
            <li>
              <b>Tenue correcte exigée (ça reste un mariage quand même !)</b>
            </li>
            <li>
              <b>Pas de blanc, c’est réservé à la Mariée !</b>
            </li>
            <li>
              <b>
                Par contre, venez le plus coloré possible, c’est l’été, c’est la
                fête et ce sera plus beau sur les photos ;)
              </b>
            </li>
            <li>
              <b>
                Venez avec une touche musicale pour accessoiriser au mieux votre
                plus belle tenue de l’année : une cravate, un noeud papillon, un
                bracelet, un pendentif, des boucles d’oreilles, une broche, du
                vernis sur les ongles, des boutons de manchettes, une pochette
                de costume, des chaussettes, un accessoire dans les cheveux (une
                barrette, un serre-tête, …), voire même un chapeau !
              </b>
              <br />
              <b>
                ⇒ Attention, un concours du plus bel accessoire vous permettra
                de gagner une bonne bouteille de champagne !
              </b>
            </li>
          </ul>

          <div className="covid-information">
            <h5>Une petite note sur le COVID...</h5>
            <p>
              Le protocole et les mesures sanitaires prescrits par le
              gouvernement seront également applicable à notre cérémonie. A ce
              jour, un <b>passe vaccinal</b> valide sera demandé. Suivant
              l'évolution de la situation, nous mettrons cette page à jour et ne
              manquerons pas de vous tenir informés !
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Theme;
