import { Col, Row } from "react-bootstrap";
import "./Portrait.scss";

function Portrait() {
  return (
    <>
      <section
        id="subheader"
        className="dark no-top no-bottom"
        style={{
          backgroundImage: "url(images/photos/headers/portrait.jpg)",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <div className="wrap">
          <div className="container">
            <div className="row">
              <div
                className="col-md-12 text-center fadeScroll relative"
                data-scroll-speed="2"
              >
                <h2 data-scroll-speed="8">Notre</h2>
                <div className="wm" data-scroll-speed="3">
                  Portrait
                </div>
                <div className="spacer-double"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div id="content" className="portrait-page">
        <div className="container">
          <p
            style={{
              fontFamily: "Quintessential",
              fontSize: "18px",
              lineHeight: "30px",
              fontWeight: "bold",
              color: "#c6913b",
            }}
          >
            Il était une fois une pétillante portugaise qui était venue rendre
            visite à sa copine Seetie pour célébrer sa pendaison de crémaillère
            dans sa nouvelle ville de Lyon. Un lorrain barbu, lui aussi ami de
            Seetie, y fut convié. Quelques fous rires plus tard, la magie opéra
            et le coup de foudre s’abattit sur nos deux tourtereaux en ce samedi
            19 mars 2016 !
          </p>
          <h2>Mais qui sont nos deux amoureux ?</h2>
          <Row>
            <Col>
              <div className="portrait-text">
                <b>Amélie</b>
                <p>
                  Un grand sourire sous une tignasse rebelle
                  <br />
                  Une vraie princesse comme dans les contes Disney, mais très
                  débrouillarde !
                  <br />
                  Parisienne de naissance, elle en a gardé la classe en arrivant
                  à Lyon
                  <br />
                  Danseuse de Modern Jazz depuis toute petite
                  <br />
                  Harpiste à ses heures perdues
                  <br />
                  Grande lectrice d’Agatha Christie
                  <br />
                  S’endort devant Hercule Poirot à la télé
                  <br />
                  Régime alimentaire : du thé, du bacalhau et des légumes verts
                  <br />
                  Aime : voyager et… Julien !
                </p>
              </div>
            </Col>
            <Col>
              <div className="portrait-picture">
                <img
                  src="images/photos/portraits/portrait-amelie.jpg"
                  alt="Amélie"
                  className="portrait amelie"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="portrait-picture">
                <img
                  src="images/photos/portraits/portrait-julien.jpg"
                  alt="Julien"
                  className="portrait julien"
                />
              </div>
            </Col>
            <Col>
              <div className="portrait-text">
                <b>Julien</b>
                <p>
                  Un vrai nounours : grognon à l’extérieur et doux à l’intérieur
                  <br />
                  Un chevalier (pour parfaire sa fonction, il s’est même mis à
                  l’escrime !)
                  <br />
                  Lorrain (non, Mosellan !) de naissance, il a amené
                  l’ancestrale recette de la vraie quiche lorraine dans ses
                  bagages à Lyon
                  <br />
                  Guitariste, il joue aussi bien sur une guitare classique
                  qu’une électrique
                  <br />
                  Amateur de basket (il est passé de joueur universitaire à
                  abonné à l’ASVEL)
                  <br />
                  S’endort quand sa douce conduit sous la pluie
                  <br />
                  Régime alimentaire : de la bière, de la crème et des lardons
                  <br />
                  Aime : voyager et… Amélie !
                </p>
              </div>
            </Col>
          </Row>
          <h2>Et leur histoire ?</h2>

          <p>
            Après cette foudroyante rencontre, ils se sont retrouvés à
            l’anniversaire de Julien pour une autre soirée mémorable à laquelle
            participaient ceux qui, plus tard, deviendraient leurs témoins :
            Seetie (évidemment), Nico et Momo !
          </p>
          <p>
            La suite est faite d’allers-retours en TGV entre Paris et Lyon, où
            ils découvrirent leurs villes respectives. Sur Paris, Amélie emmène
            Julien aux Champs Elysées, au Louvre, à la Villette, à la Cathédrale
            Notre Dame et même au Grand Palais pour y faire… du patin à glace !
            Et, évidemment, Amélie a fait découvrir à Julien : DisneyLand Paris
            (Julien comprendra plus tard qu’Amélie considère Disney comme sa
            seconde maison). Ils ont tellement aimé ça, qu’ils ont fini par
            prendre le pass annuel pour y aller en illimité toute une année !
          </p>
          <p>
            Sur Lyon, Julien commence l’opération séduction avec les bouchons
            lyonnais (les restaurants, pas les problèmes de circulation), la
            cuisine de Bocuse et les quartiers emblématiques (le Vieux Lyon, la
            Presqu’île, la Croix-Rousse et le Parc de la Tête d’Or). Il n’oublie
            pas les lieux de culture ! Pour leurs 6 mois, il emmène Amélie à
            l’Opéra de Lyon pour un spectacle de danse. Il l’emmènera évidemment
            à des concerts : Superbus au Transbo, Walk Off the Earth ou Ghost à
            la Halle Tony Garnier. Mais Lyon, c’est aussi ses destinations
            proches : un déjeuner au bord du lac Léman, un pique-nique au Lac
            d’Annecy, une session de ski à Chamrousse, du kayak dans l’Ain et
            quelques randonnées aux alentours.
          </p>
          <p>
            Non satisfaits de ne faire des concerts qu’à Lyon, ils s’emmènent
            mutuellement au Stade de France pour les Guns’N’Roses, à
            l’Hippodrome de Longchamp pour le festival Lollapalooza, à Londres
            pour Bring Me The Horizon ou encore à Aix-les-Bains pour le festival
            Musilac.
          </p>
          <p>
            Mais ils n’ont pas que la musique en commun, ils aiment aussi
            voyager ! Pour leurs premières vacances communes, Amélie emmène
            Julien découvrir la capitale de ses origines : Lisbonne. Après les
            sardines et le vin vert, c’est au tour de Julien d’emmener Amélie
            plus au Nord, à Stockholm, là où il a terminé ses études. Et ils ont
            tellement aimé voyager ensemble qu’ils ont remis ça : Londres, la
            Laponie suédoise en hiver, les Lofoten, la Norvège, le Danemark,
            Barcelone, l’Espagne, le Portugal, le Pays Basque français, le
            bassin d’Arcachon et la Bretagne. Et ce n’est pas fini : pour leur
            cadeau de mariage, ils veulent traverser la Terre jusqu’en
            Nouvelle-Zélande !
          </p>
          <p>
            Et ce n’est pas tout, parce que, sous leurs airs doux, nos 2
            tourtereaux adorent hurler dans les stades pour supporter leurs
            équipes ou signifier leur mécontentement sur le mauvais arbitrage.
            Ça commence au Stade de France avec la Finale du Top 14, où ils se
            retrouvent au milieu des supporters clermontois, vainqueur cette
            année. Ça continue avec le rugby, au Stade de France, pour France –
            Nouvelle-Zélande et France – Irlande et à Lyon pour Lyon – Bordeaux.
            Et un matin, Julien se réveille et propose à Amélie d’aller voir un
            match de basket : ce sera France – Argentine à l’Astroballe ! La
            salle les a tellement conquis, qu’ils recommencent avec un match de
            Pro A : ASVEL – Monaco. Et là, leur histoire basketballistique se
            concrétise complètement avec leur abonnement à l’ASVEL, qui leur
            permettra de voir des matches français et européens et même
            d’apercevoir Tony Parker !
          </p>
          <p>
            Mais ça, ce n’est que le début de l’Histoire et elle continue avec
            vous pendant l’été 2022 à Caluire et Roanne !
          </p>
        </div>
      </div>
    </>
  );
}

export default Portrait;
