function Contact() {
  return (
    <>
      <section
        id="subheader"
        className="dark no-top no-bottom"
        style={{
          backgroundImage: "url(images/photos/headers/contact.jpg)",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <div class="wrap">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 text-center fadeScroll relative"
                data-scroll-speed="2"
              >
                <h2 data-scroll-speed="8">Les</h2>
                <div class="wm" data-scroll-speed="3">
                  Contacts
                </div>
                <div class="spacer-double"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div id="content">
        <div class="container">
          <p>
            Pour toute question relative à l'organisation, vous pouvez contacter
            :
          </p>

          <div className="spacer-double"></div>

          <div className="col-md-3 text-center">
            <b>Témoins Julien</b>
            <br />
            <span>Nicolas Nunge</span>
            <br />
            <a href="mailto:nicolas.nunge@gmail.com">
              <i>nicolas.nunge@gmail.com</i>
            </a>
            <br />
            <i>06 19 15 16 67</i>
            <br />
            <br />
            <span>Mohamed Farid</span>
            <br />
            <a href="mailto:mohamedfarid@live.fr">
              <i>mohamedfarid@live.fr</i>
            </a>
            <br />
            <i>06 85 12 44 74</i>
          </div>

          <div className="col-md-3 text-center">
            <b>Maman Julien</b>
            <br />
            <span>Michèle Schmitt</span>
            <br />
            <a href="mailto:Michele.schmitt57@wanadoo.fr">
              <i>michele.schmitt57@wanadoo.fr</i>
            </a>
            <br />
            <i>06 80 22 30 98</i>
          </div>

          <div className="col-md-3 text-center">
            <b>Maman Amélie</b>
            <br />
            <span>Odette Oliveira</span>
            <br />
            <a href="mailto:odolive@hotmail.fr">
              <i>odolive@hotmail.fr</i>
            </a>
            <br />
            <i>06 60 62 39 81</i>
          </div>

          <div className="col-md-3 text-center">
            <b>Témoins Amélie</b>
            <br />
            <span>Seetie To</span>
            <br />
            <a href="mailto:seetieto@hotmail.com">
              <i>seetieto@hotmail.com</i>
            </a>
            <br />
            <i>06 04 02 26 56</i>
            <br />
            <br />
            <span>Carine Nouët</span>
            <br />
            <a href="mailto:carine.nouet@laposte.net">
              <i>carine.nouet@laposte.net</i>
            </a>
            <br />
            <i>06 01 07 22 71</i>
          </div>

          <div className="col-md-12 text-center">
            <b>Wedding Planner</b>
            <br />
            <span>Elodie Bansard</span>
            <br />
            <span>DDay Wedding Planner</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
