function Honeymoon() {
  return (
    <>
      <section
        id="subheader"
        className="dark no-top no-bottom"
        style={{
          backgroundImage: "url(images/photos/headers/wedding-gift.jpg)",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <div class="wrap">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 text-center fadeScroll relative"
                data-scroll-speed="2"
              >
                <h2 data-scroll-speed="8">Le</h2>
                <div class="wm" data-scroll-speed="3">
                  Cadeau de Mariage
                </div>
                <div class="spacer-double"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div id="content">
        <div className="container">
          <div className="row align-items-center mb-5">
            <div className="col-3">
              <img
                src="images/kdo-drapeau-nz.jpg"
                alt=""
                style={{
                  width: "100%",
                  borderTopLeftRadius: "20px",
                  borderBottomRightRadius: "20px",
                }}
              />
            </div>
            <div className="col">
              <p>
                On vous l’a dit, on aime{" "}
                <b>
                  <u>voyager</u>
                </b>{" "}
                ! Et donc, pour sceller notre union et la démarrer de la plus
                belle des manières, on souhaite traverser (littéralement) le
                globe et passer quelques semaines chez les kiwis. Alors, pour
                notre cadeau de mariage, on vous demande de participer à ce
                voyage en <b>Nouvelle-Zélande</b> !
              </p>
            </div>
          </div>
          <div className="row align-items-center mb-5">
            <div className="col">
              <p>
                Pour participer, rien de plus simple ! Il vous suffit de vous
                connecter sur le lien en bas de la page et de participer à la
                cagnotte en ligne (pour les déconnectés, une tirelire sera mise
                à disposition au château le Jour J). Mais, on vous en demandera
                un peu plus quand même : pour chaque donation, il vous faudra
                ajouter un petit mot avec votre thème favori dans ce beau pays
                divisé en deux îles. Pas d’inquiétude, on a fait nos devoirs et
                le résultat de nos recherches se trouve juste après 😉
              </p>
            </div>
            <div className="col-3">
              <img
                src="images/kdo-haka.jpg"
                alt=""
                align="right"
                style={{ width: "300px" }}
              />
            </div>
          </div>
          <div className="text-center" style={{ marginBottom: "50px" }}>
            <img
              src="images/kdo-carte.jpg"
              alt=""
              style={{
                borderTopLeftRadius: "50px",
                borderBottomRightRadius: "50px",
              }}
            />
          </div>
          <div className="row">
            <div className="col">
              <img
                src="images/kdo-vaiana.png"
                alt=""
                align="right"
                style={{ width: "300px" }}
              />
              <center className="mb-5">
                <b>
                  <u style={{ fontSize: "2rem" }}>Ile du Nord</u>
                </b>
              </center>
              <p>
                L’île du Nord (ou « <i>Te Ika-a-Maui</i> », qui signifie « Ile
                du Poisson de Maui (
                <i>
                  métamorphe, demi-dieu du vent et de la mer, idole des hommes)
                  » en maori
                </i>
                ) est la plus petite des deux îles qui composent la
                Nouvelle-Zélande. Elle est celle qui accueille la capitale
                Auckland et qui nous verra donc atterrir et redécoller.
              </p>
              <p>
                <b
                  style={{
                    fontSize: "1.75rem",
                    marginBottom: "20px",
                    display: "block",
                  }}
                >
                  1. <u>Sport :</u>
                </b>
                <ul>
                  <li className="mb-4">
                    <b>
                      <i>Taupo :</i>
                    </b>
                    <br />
                    une sortie en kayak sur le lac <i>Waikato</i> et une balade
                    autour des cratères de Lune et des chutes <i>Huka</i>,
                    suivies d’une session détente dans le Spa alimenté par les
                    sources chaudes volcaniques de <i>Wairakei</i>
                  </li>
                  <li className="mb-4">
                    <b>
                      <i>Tongariro :</i>
                    </b>
                    <br />à proximité de <i>Taupo</i>, la randonnée{" "}
                    <i>Alpine Crossing</i> de 19,4 km nous occupera pendant 7 à
                    8 heures, en nous ouvrant la vue sur des paysages
                    volcaniques – on verra bien si elle mérite son surnom de «
                    Plus Belle Randonnée à la journée de Nouvelle-Zélande »
                  </li>
                  <li className="mb-4">
                    <b>
                      <i>Hahei :</i>
                    </b>
                    <br />
                    une balade à vélo le long de l’eau permet de voir{" "}
                    <i>Cathedral Cove</i> et le jacuzzi naturel de{" "}
                    <i>Hot Water Beach</i> (avec comme récompense, une
                    dégustation de bière dans une des nombreuses brasseries
                    artisanales locales)
                  </li>
                  <li className="mb-4">
                    <b>
                      <i>Lac de Rotura :</i>
                    </b>
                    <br />
                    encore un spot pour une sortie kayak, cette fois-ci au
                    milieu des maoris
                  </li>
                  <li className="mb-4">
                    <b>
                      <i>Mont Taranaki :</i>
                    </b>
                    <br />
                    sur la route entre <i>Wellington</i> et <i>Auckland</i> se
                    dresse cette montagne et ses nombreux sentiers de randonnées
                    (allant de 1 heure à 3 jours !)
                  </li>
                </ul>
              </p>
              <p>
                <b
                  style={{
                    fontSize: "1.75rem",
                    marginBottom: "20px",
                    display: "block",
                  }}
                >
                  2. <u>Histoire-Géo :</u>
                </b>
                <ul>
                  <li className="mb-4">
                    <b>
                      <i>Taupo (encore !) :</i>
                    </b>
                    <br />
                    grand lieu touristique, <i>Taupo</i> abrite notamment un
                    musée retraçant l’histoire du pays, de la culture maorie
                    (avec le
                    <i>Te Aroha o Rongoheikume</i>) à la révolution industrielle
                  </li>
                  <li className="mb-4">
                    <b>
                      <i>Rotura :</i>
                    </b>
                    <br />
                    le cœur de la culture maorie avec ses villages très typés (
                    <i>Whakarewarewa</i>, <i>Ohinemutu</i> et <i>Te Puia</i>) et
                    sa forêt faite pour les balades (<i>Redwoods</i>)
                  </li>
                </ul>
              </p>
              <p>
                <b
                  style={{
                    fontSize: "1.75rem",
                    marginBottom: "20px",
                    display: "block",
                  }}
                >
                  3. <u>Civilisation Moderne :</u>
                </b>

                <ul>
                  <li className="mb-4">
                    <b>
                      <i>Wellington :</i>
                    </b>
                    <br />
                    capitale politique du pays, la ville est le lieu d’accueil
                    de <i>Te Papa</i> (le musée national de Nouvelle-Zélande),
                    de nombreuses micro-brasseries ou encore des fameux
                    vignobles de <i>Wairarapa</i> (dont les allées accueillent
                    les volontés de balades de ses visiteurs)
                  </li>
                  <li className="mb-4">
                    <b>
                      <i>Auckland :</i>
                    </b>
                    <br />
                    cette ville sera la première à nous voir arriver, car elle
                    abrite l’aéroport international de Nouvelle-Zélande. On peut
                    aussi y visiter son musée (dont les collections sont
                    consacrées aux îles du Pacifique), la <i>
                      One Tree Hill
                    </i>{" "}
                    et le
                    <i>Mount Eden</i> qui dominent la ville et offrent une vue
                    panoramique des environs, la plage de <i>Karekare</i> (lieu
                    de tournage du film « <i>La Leçon de Piano</i> », le
                    quartier de
                    <i>Ponsonby</i> (avec ses restaurants, ses bars et ses
                    grandes enseignes de mode), mais aussi le fameux{" "}
                    <i>Eden Park</i> (l’antre des All-Blacks)
                  </li>
                </ul>
              </p>
              <p>
                <b
                  style={{
                    fontSize: "1.75rem",
                    marginBottom: "20px",
                    display: "block",
                  }}
                >
                  4. <u>Gastronomie :</u>
                </b>
                <ul>
                  <li className="mb-4">
                    <b>
                      <i>L'île de Waiheke :</i>
                    </b>
                    <br />
                    au milieu de ses grandes plages de sable blanc, l’île abrite
                    les vignobles les plus réputés du pays (tout ça à une
                    longueur de ferry d’Auckland)
                  </li>
                </ul>
              </p>
              <p>
                <b
                  style={{
                    fontSize: "1.75rem",
                    marginBottom: "20px",
                    display: "block",
                  }}
                >
                  5. <u>Cinéma :</u>
                </b>
                <ul>
                  <li className="mb-4">
                    <b>
                      <i>Matamata :</i>
                    </b>
                    <br />
                    le village abrite le décor de <i>Hobbiton</i>, village des
                    Hobbits dans le film de Peter Jackson, « Le Seigneur des
                    Anneaux »
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className="row" style={{ marginTop: "50px" }}>
            <div className="col">
              <img
                src="images/kdo-jade.jpg"
                alt=""
                align="left"
                style={{ width: "300px", padding: "30px" }}
              />
              <center className="mb-5">
                <b>
                  <u style={{ fontSize: "2rem" }}>Ile du Sud</u>
                </b>
              </center>
              <p>
                L’île du Sud (ou « <i>Te Wai Pounamu</i> », qui signifie « La
                Rivière des Pierres Vertes » en <i>maori</i>, moins Disney, mais
                plus poétique 😉) est la plus grande des deux îles. Elle est
                aussi la plus « nature » et la plus dense en activités.{" "}
              </p>
              <p>
                <b
                  style={{
                    fontSize: "1.75rem",
                    marginBottom: "20px",
                    display: "block",
                  }}
                >
                  1. <u>Sport :</u>
                </b>
                <ul>
                  <li className="mb-4">
                    <b>
                      <i>Aoraki/Mont Cook et le Lac Pukaki :</i>
                    </b>
                    <br />
                    la balade du <i>Hooker Valley Track</i> permet, en 3 heures,
                    d’aller du <i>Stocking Stream</i> au Glacier Hooker en
                    offrant au passage une superbe vue sur l’<i>Aoraki</i>{" "}
                    (renommé Mont <i>Cook</i>), point culminant d’Australasie
                  </li>
                  <li className="mb-4">
                    <b>
                      <i>Glenorchy / Lac Wakatipu : </i>
                    </b>
                    <br />
                    autour de <i>Queenstown</i>, des balades en bateau
                    permettent d’admirer le lac <i>Wakatipu</i>. Partant de{" "}
                    <i>Glenorchy</i>, des randonnées à pied et en kayak
                    permettent de découvrir les environs.
                  </li>
                  <li className="mb-4">
                    <b>
                      <i>Wanaka :</i>
                    </b>
                    <br />
                    ce lac situé dans la région de l’<i>Otago</i> se laisse
                    traverser en kayak ou en paddle et longer à vélo. Au
                    passage, une randonnée jusqu’au sommet du <i>Mount Iron</i>{" "}
                    donne une vue imprenable sur le lac.
                  </li>
                  <li className="mb-4">
                    <b>
                      <i>Milford Sound :</i>
                    </b>
                    <br />
                    au Sud de l’île du Sud se trouvent les fjords, dont{" "}
                    <i>Milford Sound</i>, qui offre des randonnées à pied ou en
                    kayak. Sur la route vers <i>Te Anau</i>, un sentier partant
                    de <i>Te Anau Downs</i> nous emmène vers le petit lac
                    glaciaire appelé lac Mistletoe.
                  </li>
                  <li className="mb-4">
                    <b>
                      <i>Franz Josef Glacier et Fox Glacier :</i>
                    </b>
                    <br />
                    les <i>maoris</i> avaient baptisé le <i>Franz Joseph</i> «{" "}
                    <i>Ka Roimata ou Hine Hukatere</i> » (les larmes de la fille
                    des avalanches). Ces deux glaciers offrent de belles
                    randonnées, ainsi qu’une jolie balade le long du lac{" "}
                    <i>Matheson</i>.
                  </li>
                  <li className="mb-4">
                    <b>
                      <i>Abel Tasman National Park :</i>
                    </b>
                    <br />
                    l’un des plus grands parcs de Nouvelle-Zélande, il offre une
                    randonnée de 60 km, mais aussi de belles balades en kayak le
                    long de son littoral de plages boisées
                  </li>
                </ul>
              </p>
              <p>
                <b
                  style={{
                    fontSize: "1.75rem",
                    marginBottom: "20px",
                    display: "block",
                  }}
                >
                  2. <u>Histoire-Géo :</u>
                </b>
                <ul>
                  <li className="mb-4">
                    <b>
                      <i>Akaroa :</i>
                    </b>
                    <br />
                    lieu le plus français du pays (là où la première communauté
                    française a posé ses bagages), <i>Akaroa</i> abrite un musée
                    retraçant les phases de peuplement de la péninsule, la
                    fameuse <i>Giant’s House</i> au style inspiré de Gaudi et
                    Miro, ainsi qu’une colonie de manchots
                  </li>
                  <li className="mb-4">
                    <b>
                      <i>Greymouth / Punakaiki / Hokitika :</i>
                    </b>
                    <br />
                    ces 3 lieux géographiquement proches offrent de courtes
                    balades en forêt avec des points de vue sur les{" "}
                    <i>Pancake Rocks</i> ou les lacs alentour. <i>Hokitika</i>{" "}
                    est le lieu de fabrication de bijoux et d’objets d’art en
                    jade (qui a dit « bonne idée de cadeau » ?), mais aussi de
                    ses gorges dont la courte balade vaut le détour.
                  </li>
                  <li className="mb-4">
                    <b>
                      <i>Dunedin :</i>
                    </b>
                    <br />
                    la plus écossaise des villes néo-zélandaises (et dont le nom
                    a inspiré une dynastie royale à JRR Tolkien) abrite une
                    superbe gare entourée d’un grand parc, l’<i>Otago Museum</i>
                    regroupant une bonne partie de l’histoire maorie locale, de
                    superbes jardins (Jardin Botanique, Jardin Chinois). Un peu
                    à l’extérieur se trouvent les <i>Moeraki Boulders</i>, ces
                    pierres rondes mystérieuses posées sur une plage de sable
                    fin.
                  </li>
                  <li className="mb-4">
                    <b>
                      <i>Lac Tekapo :</i>
                    </b>
                    <br />à la croisée de <i>Queenstown</i>, <i>Christchurch</i>{" "}
                    et <i>Aoraki</i>, le lac offre plusieurs randonnées de
                    toutes distances. Classé « Dark Sky Reserve », il n’est pas
                    pollué par les lumières artificielles et permet de voir les
                    étoiles, comme c’est très (trop ?) rarement le cas par chez
                    nous.
                  </li>
                </ul>
              </p>
              <p>
                <b
                  style={{
                    fontSize: "1.75rem",
                    marginBottom: "20px",
                    display: "block",
                  }}
                >
                  3. <u>Civilisation Moderne :</u>
                </b>
                <ul>
                  <li className="mb-4">
                    <b>
                      <i>Christchurch :</i>
                    </b>
                    la « grande ville » de l’île du Sud, point d’ancrage pour
                    les visites alentour, elle regorge de vie (bars et
                    restaurants, surtout le quartier de <i>Lyttelton</i>) grâce
                    à sa renaissance après les séismes de 2010 et 2011
                  </li>
                  <li className="mb-4">
                    <b>
                      <i>Te Anau :</i>
                    </b>
                    <br />
                    petite ville située au bord du lac du même nom, elle fait le
                    lien entre la région de <i>Queenstown</i> et les{" "}
                    <i>fjords</i> et abrite quelques très bons restaurants
                  </li>
                  <li className="mb-4">
                    <b>
                      <i>Kaikoura :</i>
                    </b>
                    <br />
                    lieu d’observation de la faune locale (baleines, dauphins et
                    manchots), une balade de 11,7 km partant du centre-ville
                    permet d’observer les phoques et les oiseaux tout en
                    longeant la côte
                  </li>
                  <li className="mb-4">
                    <b>
                      <i>Nelson :</i>
                    </b>
                    <br />
                    ville la plus ensoleillée et la plus agréable du pays,
                    Nelson regroupe le <i>Provincial Museum</i> dédié au
                    patrimoine culturel et à l’histoire naturelle locaux, la
                    coopérative lainière <i>Fibre Spectrum</i>, le joaillier du
                    Seigneur des Anneaux (Jens Hansen), la cathédrale{" "}
                    <i>Christ Church</i> et le «
                    <i>Centre de la Nouvelle-Zélande</i> » au sein de la réserve
                    botanique
                  </li>
                </ul>
              </p>
              <p>
                <b
                  style={{
                    fontSize: "1.75rem",
                    marginBottom: "20px",
                    display: "block",
                  }}
                >
                  4. <u>Gastronomie :</u>
                </b>
                <ul>
                  <li className="mb-4">
                    <b>
                      <i>Queenstown :</i>
                    </b>
                    <br />
                    plutôt que de se jeter du haut du pont à l’élastique, la
                    vallée de <i>Gibbston</i> regorge de vignobles, dont le
                    Peregrine, offrant aux visiteurs une dégustation de vins
                  </li>
                  <li className="mb-4">
                    <b>
                      <i>Marlborough :</i>
                    </b>
                    <br />
                    encore une ville fournie en vignobles, mais qui offre, en
                    plus, la possibilité de dîner au milieu des vignes !
                  </li>
                </ul>
              </p>
              <p style={{ textAlign: "center" }}>
                <b>
                  <u>
                    Pour participer à la cagnotte via PayPal, cliquez sur le
                    bouton ci-dessous :
                  </u>
                </b>
                <form
                  action="https://www.paypal.com/donate"
                  method="post"
                  target="_top"
                >
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="FF5CMHM55DR7S"
                  />
                  <input
                    type="image"
                    src="https://www.paypalobjects.com/fr_FR/FR/i/btn/btn_donateCC_LG.gif"
                    border="0"
                    name="submit"
                    title="PayPal - The safer, easier way to pay online!"
                    alt="Bouton Faites un don avec PayPal"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypal.com/fr_FR/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Honeymoon;
