import { useState } from "react";
import { useEffect } from "react";
import { createRef } from "react";
import backgroundMusic from "../../background.mp3";
import "./Player.scss";

function Player() {
  const audioRef = createRef();
  const [isPlaying, setPlaying] = useState(true);

  const togglePlayer = () => {
    if (document.getElementById("audioPlayer").paused) {
      document.getElementById("audioPlayer").play();
      setPlaying(true);
    } else {
      document.getElementById("audioPlayer").pause();
      setPlaying(false);
    }
  };

  useEffect(() => {
    const player = document.getElementById("audioPlayer");
    player.volume = 0.1;
    setPlaying(player.playing);
  }, [setPlaying]);

  return (
    <div id="player">
      <audio
        src={backgroundMusic}
        autoPlay={true}
        loop={true}
        controls={false}
        ref={audioRef}
        id="audioPlayer"
      ></audio>
      <span
        onClick={() => togglePlayer()}
        className={`fa fa-${isPlaying ? "pause" : "play"}`}
      ></span>
    </div>
  );
}

export default Player;
