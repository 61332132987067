import { useEffect, useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { createRef } from "react";
import "./Home.scss";
import { Col, Row } from "react-bootstrap";

function Home() {
  const overlap = 50;
  const [innerHeight, setInnerHeight] = useState(window.innerHeight - overlap);
  const [mTop, setMTop] = useState(0);
  var nameRef = createRef();

  useEffect(() => {
    setInnerHeight(window.innerHeight - overlap);
    const picheight = nameRef.current.offsetHeight;
    const mtop = (window.innerHeight - picheight) / 2;
    setMTop(mtop);
  }, [setInnerHeight, setMTop, nameRef]);

  var msPerDay = 8.64e7;

  var now = new Date();
  var weddingDay = new Date(2022, 7, 6);

  // Set to noon - avoid DST errors
  weddingDay.setHours(12, 0, 0);
  now.setHours(12, 0, 0);

  // Round to remove daylight saving errors
  const days = Math.round((weddingDay - now) / msPerDay);

  return (
    <>
      <section
        id="section-hero"
        className="
              full-height
              relative
              z1
              owl-slide-wrapper
              no-top no-bottom
              text-light
            "
        data-stellar-background-ratio=".2"
        style={{ height: innerHeight }}
      >
        <div
          className="center-y fadeScroll relative"
          data-scroll-speed="4"
          style={{ marginTop: mTop }}
          ref={nameRef}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2">
                <div className="row">
                  <div className="spacer-single"></div>
                  <div className="col-md-5 text-right text-center-sm relative">
                    <h2 className="name">Julien</h2>
                  </div>
                  <div className="col-md-2 text-center">
                    <span className="deco-big" data-scroll-speed="2">
                      &amp;
                    </span>
                  </div>
                  <div className="col-md-5 text-left text-center-sm relative">
                    <h2 className="name">Amélie</h2>
                  </div>
                  <div className="col-md-12 text-center">
                    <h3 className="date">J-{days}</h3>
                    <h4 className="countdown">Samedi 6 août 2022</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center scroll-down">
          <i class="fas fa-chevron-down"></i>
        </div>

        <div id="custom-owl-slider" className="owl-slide" data-scroll-speed="2">
          {/* <OwlCarousel items={1} autoplay={true} margin={0} className='owl-theme' loop> */}
          <div
            className="item"
            style={{
              height: innerHeight,
              backgroundImage: "url(images/photos/home/p1.jpg)",
            }}
          ></div>
          <div
            className="item"
            style={{
              height: innerHeight,
              backgroundImage: "url(images/photos/home/p2.jpg)",
            }}
          ></div>
          <div
            className="item"
            style={{
              height: innerHeight,
              backgroundImage: "url(images/photos/home/p3.jpg)",
            }}
          ></div>
          <div
            className="item"
            style={{
              height: innerHeight,
              backgroundImage: "url(images/photos/home/p4.jpg)",
            }}
          ></div>
        </div>
        {/* </OwlCarousel> */}
        <div class="custom-shape-divider-bottom-1642582257">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
              opacity=".25"
              class="shape-fill"
            ></path>
            <path
              d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
              opacity=".5"
              class="shape-fill"
            ></path>
            <path
              d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
      </section>
      <section className="programme">
        <div className="container">
          <h2>Le programme</h2>
          <h3>Samedi 6 août 2022</h3>
          <h4>
            Eglise de l'Immaculée Conception
            <span>(Place de l'Église, 69300 Caluire-et-Cuire)</span>
          </h4>

          <Row>
            <Col>
              <img src="images/pictos/eglise.svg" width="150" alt="Chateau" />{" "}
              <span class="time">14h00</span>Cérémonie religieuse à l’église de
              l’Immaculée Conception de Caluire-et-Cuire
            </Col>
            <Col>
              <img src="images/pictos/logo-ja.svg" width="150" alt="Chateau" />{" "}
              <span class="time">15h30</span>Fin de la cérémonie et sortie des
              mariés
            </Col>
            <Col>
              <img src="images/pictos/carosse.svg" width="150" alt="Chateau" />{" "}
              <span class="time">16h00</span>Départ des invités et des mariés
              vers le Château de Matel
            </Col>
          </Row>

          <h4>
            Château de Matel
            <span>
              (19 Allée du Château de Matel, 42300 Roanne - à 1h15 de trajet
              depuis Caluire)
            </span>
          </h4>

          <Row>
            <Col>
              <img
                src="images/pictos/chateau-matel.svg"
                width="150"
                alt="Chateau"
              />
              <span class="time">17h30</span>Début du vin d’honneur
            </Col>
            <Col>
              <img src="images/pictos/ribbon.svg" width="150" alt="Rubans" />
              <span class="time">18h00</span>Cérémonie des rubans animée par les
              témoins
            </Col>
            <Col>
              <img src="images/pictos/diner.svg" width="150" alt="Chateau" />
              <span class="time">20h30</span>Début du dîner
            </Col>
            {/* </Row>
          <Row> */}
            <Col>
              <img src="images/pictos/dance.svg" width="150" alt="Chateau" />
              <span class="time">23h30</span>Ouverture du bal
            </Col>
            <Col>
              <img src="images/pictos/disco.svg" width="150" alt="Rubans" />
              <span class="time">Minuit</span>Grosse soirée !
            </Col>
          </Row>

          <h3>Dimanche 7 août</h3>
          <h4>
            Château de Matel
            <span>(19 Allée du Château de Matel, 42300 Roanne)</span>
          </h4>
          <Row>
            <Col md={{ span: 3, offset: 3 }}>
              <img src="images/pictos/brunch.svg" width="150" alt="Chateau" />
              <span class="time">11h00</span>Brunch
            </Col>
            <Col md={3}>
              <img src="images/pictos/carosse.svg" width="150" alt="Rubans" />
              <span class="time">15h00</span>Départs
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}

export default Home;
