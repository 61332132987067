import { useState } from "react";
import { useReducer } from "react";
import { Link } from "react-router-dom";
import "./Response.scss";

const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value,
  };
};

function Response() {
  const [formData, setFormData] = useReducer(formReducer, {});
  const [formStatus, setFormStatus] = useState(0);
  // const [_, setFormError] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    var sanitizedFormData = new FormData();
    for (var key in formData) {
      sanitizedFormData.append(key, formData[key]);
    }

    fetch("/response.php", {
      method: "POST",
      body: sanitizedFormData,
    })
      .then((r) => {
        return r.json();
      })
      .then((response) => {
        console.log(response);
        if (response.error === null) {
          setFormStatus(1);
        } else {
          setFormStatus(-1);
          // setFormError(response.error);
        }
      });
  };

  return (
    <>
      <div class="rsvp">
        <div class="link-close">
          <Link to="/">Revenir au site</Link>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
              <h2 class="deco id-color">
                <span>Vous serez parmi nous ?</span>
              </h2>
              <h2 data-wow-delay=".2s">Samedi 6 août 2022</h2>
            </div>

            <div class="spacer-double"></div>

            <div class="col-md-5 col-md-offset-1 text-right">
              <h3>Cérémonie</h3>
              14h00
              <br /> Eglise de l'Immaculée Conception
              <br /> 69300 Caluire
              <br />
            </div>

            <div class="col-md-5">
              <h3>Réception</h3>
              17h30
              <br /> Château de Matel
              <br /> 42300 Roanne
              <br />
            </div>

            <div class="spacer-double"></div>

            <form
              name="rsvp"
              id="rsvp_form"
              class="form-underline"
              method="post"
              onSubmit={handleSubmit}
              style={{ display: formStatus !== 1 ? "block" : "none" }}
            >
              <div class="col-md-12">
                <h4>Formulaire réponse</h4>
              </div>
              <div class="col-md-4">
                <input
                  type="text"
                  name="name"
                  id="name"
                  class="form-control"
                  placeholder="Votre nom"
                  required=""
                  maxlength="50"
                  onChange={setFormData}
                />
              </div>
              <div class="col-md-4">
                <input
                  type="text"
                  name="email"
                  id="email"
                  class="form-control"
                  placeholder="Votre Email"
                  required=""
                  maxlength="50"
                  onChange={setFormData}
                />
              </div>
              <div class="col-md-4">
                <input
                  type="phone"
                  name="phone"
                  id="phone"
                  class="form-control"
                  placeholder="Votre Téléphone"
                  required=""
                  maxlength="50"
                  onChange={setFormData}
                />
              </div>
              <div class="col-md-3">
                <select
                  id="attendance"
                  name="attendance"
                  size="1"
                  class="form-control"
                  onChange={setFormData}
                >
                  <option disabled selected>
                    Participez-vous ?
                  </option>
                  <option>Oui, vin d'honneur et repas</option>
                  <option>
                    Oui, vin d'honneur, repas et brunch (le lendemain)
                  </option>
                  <option>Non</option>
                </select>
              </div>
              <div class="col-md-3">
                <input
                  type="number"
                  name="nb_adults"
                  id="nb_adults"
                  class="form-control"
                  placeholder="Nombre d'adultes"
                  required=""
                  maxlength="50"
                  min={0}
                  onChange={setFormData}
                />
              </div>
              <div class="col-md-3">
                <input
                  type="number"
                  name="nb_kids"
                  id="nb_kids"
                  class="form-control"
                  placeholder="Nombre d'enfants (4-11 ans)"
                  required=""
                  maxlength="50"
                  min={0}
                  onChange={setFormData}
                />
              </div>
              <div class="col-md-3">
                <input
                  type="number"
                  name="nb_teens"
                  id="nb_teens"
                  class="form-control"
                  placeholder="Nombre d'enfants (12 ans et +)"
                  required=""
                  maxlength="50"
                  min={0}
                  onChange={setFormData}
                />
              </div>
              <div class="col-md-12">
                <input
                  type="text"
                  name="comment"
                  id="comment"
                  class="form-control"
                  placeholder="Commentaire (allergies, régimes spéciaux...)"
                  required=""
                  maxlength="50"
                  onChange={setFormData}
                />
              </div>
              <div class="col-md-12">
                <h4>Hébergement</h4>
                <p>
                  Pour vous loger, deux hôtels sont situés à proximité du
                  Château. Vous pouvez y réserver un hébergement.
                </p>
              </div>
              <div class="col-md-6">
                <h5>Hôtel Kyriad Direct</h5>
                <p>
                  38 rue de Matel 42300 Roanne
                  <br />
                  (Environ 20 minutes à pied)
                  <br />
                  <a
                    style={{ color: "#c6913b", textDecoration: "underline" }}
                    href="https://direct-roanne.kyriad.com/fr-fr/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Cliquez ici pour réserver
                  </a>
                  <br />
                  <i>(Budget moyen : 55-60 € / chambre)</i>
                </p>
              </div>
              <div class="col-md-6">
                <h5>Hôtel Ibis Budget</h5>
                <p>
                  60 rue de Matel 42300 Roanne
                  <br />
                  (Environ 15 minutes à pied)
                  <br />
                  <a
                    style={{ color: "#c6913b", textDecoration: "underline" }}
                    href="https://all.accor.com/lien_externe.svlt?goto=fiche_hotel&code_hotel=7409&merchantid=seo-maps-FR-7409&sourceid=aw-cen&utm_medium=seo+maps&utm_source=google+Maps&utm_campaign=seo+maps&y_source=1_MTUzNjA4NDUtNzE1LWxvY2F0aW9uLmdvb2dsZV93ZWJzaXRlX292ZXJyaWRl"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Cliquez ici pour réserver
                  </a>
                  <br />
                  <i>(Budget moyen : 50-55 € / chambre)</i>
                </p>
              </div>

              <div class="col-md-12 text-center">
                <div class="spacer-single"></div>
                <input
                  type="submit"
                  id="submit"
                  value="Je valide ma réponse"
                  class="btn btn-custom"
                />
              </div>
            </form>
            <div
              id="success_message"
              style={{ display: formStatus === 1 ? "block" : "none" }}
            >
              <h1>Merci pour votre réponse !</h1>
            </div>

            <div class="spacer-single"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Response;
