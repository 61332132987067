import "./Location.scss";

function Location() {
  return (
    <>
      <section
        id="subheader"
        className="dark no-top no-bottom"
        style={{
          backgroundImage: "url(images/photos/headers/locations.jpg)",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <div class="wrap">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 text-center fadeScroll relative"
                data-scroll-speed="2"
              >
                <h2 data-scroll-speed="8">Les lieux</h2>
                <div class="wm" data-scroll-speed="3">
                  Eglise & Château
                </div>
                <div class="spacer-double"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div id="content">
        <div class="container">
          <h2>L'Eglise de l'Immaculée Conception...</h2>
          <p>
            <img
              src="images/eglise.jpg"
              align="right"
              className="illustration"
              alt="Eglise de l'Imaculée Conception"
            />
            À la suite de la proclamation du dogme de l'Immaculée Conception par
            Pie IX, le 8 décembre 1854, la ville de Lyon cède gratuitement en
            1855 au diocèse un terrain pour y bâtir l'église dédiée à ce dogme.
          </p>
          <p>
            L’architecte de cette église est Pierre Bossan, surtout connu pour
            avoir peu après dessiné les plans de Notre-Dame de Fourvière. La nef
            de l'église est bâtie entre 1856 et 1859. Une seconde vague de
            travaux, de 1889 à 1893, permet la construction du chœur et des
            chapelles. C'est également Bossan qui construit le presbytère
            attenant à l'église. Pour l'ensemble du chantier, Bossan est assisté
            de William Léo (1820-1891), collaborateur occasionnel. La décoration
            intérieure et la réalisation de la façade sont le fait d'un autre
            élève de Bossan, Charles-Marie Franchet. Les vitraux de l'édifice,
            douze ensembles de trois vitraux chacun, sont réalisés par Lucien
            Bégule. Peu après la construction de l'église, en 1874, la paroisse
            de l’Immaculée-Conception est la plus peuplée du diocèse, avec 27
            000 habitants.
          </p>
          <p>
            Comme plusieurs autres édifices religieux de Bossan, l'église
            présente de l'extérieur un aspect fortifié, dont Bossan a trouvé
            l'inspiration lors de ses voyages en Sicile et en Perse.
          </p>
          <p>
            L'église se caractérise entre autres par sa coupole, forme alors à
            la mode chez les architectes orientalistes, mais assez rare chez
            Bossan, qui ne l'a utilisée qu'à deux autres reprises, dans les
            basiliques d'Ars et de Lalouvesc. Le plan de l'église (édifice en
            croix laine centré sur un dôme octogonal avec chapelles latérales et
            déambulatoire) resservira d'ailleurs pour la basilique d'Ars.
          </p>
          <p>
            Une autre particularité, structurelle celle-ci, est d'avoir fait
            porter la masse des voûtes par des groupes de colonnes très minces,
            semblant hors de proportion, grâce à l'utilisation de la voûte en
            décharge. Cette innovation technique sera plus tard reprise à
            Fourvière.
          </p>

          <h2>Le Château de Matel...</h2>
          <p>
            Le lieu de Matel fut habité dès l’époque gallo-romaine ainsi qu’en
            témoigne une tête en bronze du musée de Roanne. Il est mentionné au
            XIIIe siècle. Au XVIe siècle, il y avait déjà à Matel un manoir qui
            fut incendié pendant les troubles de la Ligue. Il fut reconstruit
            sous Henri IV par un sieur Jean Chézard, gentilhomme originaire de
            Florence et fixé en Roannais. Il eut pour fille la grande mystique
            française, Jeanne Chézard de Mâtel (1596-1670) fondatrice de l’ordre
            du Verbe Incarné. Le manoir, encore reconstruit par les Dumas, passa
            à Antoine Terray de Rosière, frère du redouté abbé Terray,
            contrôleur général des finances, puis par alliance aux du Myrat et
            aux du Rosier.
          </p>
          <p>
            Il devint ensuite la propriété de l’abbé Claude Eléonore de
            Chavagnac qui y construisit une chapelle et le légua, après sa mort
            en 1811, à son neveu le Comte Camille Marie Alexandre de Chavagnac.
            Il fut acquis en 1849 par Charles Michel et Mme J.-B. Michel qui le
            laissèrent à leur nièce, Eugénie Michel. Celle-ci épousa le
            commandant Poulot qui eut pour enfants Charles Poulot, ingénieur
            chimiste (1859-1929) et Mme Calemard de Genestoux.
          </p>
          <p>
            Après avoir appartenu de janvier à juillet 1919 à André Citroën, il
            fut racheté par la famille Calemard de Genestoux. Enfin en août
            1941, il est acheté avec 40 hectares, par la société France-Rayonne.
            En 1990 le château fût racheté par des japonais pour y implanter une
            école de cuisine et de langue : l'école des 3 ponts. Le Château est
            à présent la résidence principale d'Olivier Bourgeois, qui le loue à
            nos beaux tourtereaux pour leur mariage !
          </p>
          <div className="text-center">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/EZ4z2rLBRW4?controls=0&autoplay=1&modestbranding=1&fs=0"
              title="YouTube video player"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default Location;
