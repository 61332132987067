import { useState } from "react";
import { Link } from "react-router-dom";
import Player from "../Player/Player";
import "./Navigation.scss";

function Navigation() {
  const [isOpen, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!isOpen);
  };

  return (
    <>
      <span id="menu-btn" onClick={toggleMenu}></span>
      <nav>
        <span
          className={`close-menu-btn ${isOpen ? "shown" : null}`}
          onClick={toggleMenu}
        ></span>
        <ul
          id="mainmenu"
          className={`animated faster ${isOpen ? "open fadeInDown" : null}`}
        >
          <li>
            <Link to="/">Accueil</Link>
          </li>
          <li>
            <Link to="/notre-portrait">Notre Portrait</Link>
          </li>
          <li>
            <Link to="/lieux">Les Lieux</Link>
          </li>
          <li>
            <Link to="/theme">Infos Pratiques</Link>
          </li>
          <li>
            <Link to="/cadeau-de-mariage">Cadeau de Mariage</Link>
          </li>
          <li>
            <a href="https://accesclient.lesdeuxgrands.fr/guestlogin/mariageamlieandjulien/">
              Photos
            </a>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          {/* <li>
            <Link to="/prestataires">Prestataires</Link>
          </li> */}
          <li>
            <Link to="/rsvp">Réservation</Link>
          </li>
          {/* <li>
            <Link to="/rsvp">COVID</Link>
          </li> */}
          <li>
            <Player></Player>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Navigation;
